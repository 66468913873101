
import { IGlobalState } from "@/store";
import HEADER_MENU from "@/typings/app";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
declare let ssoClient: any;

export default defineComponent({
  setup() {
    const store = useStore<IGlobalState>();
    const router = useRouter();

    // 头部当前选中的菜单下标
    const headerMenuIndex = computed({
      get: () => store.state.app.headerMenuIndex,
      set: (val) => {
        store.state.app.headerMenuIndex = val;
      },
    });
    const state = reactive({
      userId: computed(() => store.state.user.userId),
      realName: computed(() => store.state.user.realName),
      logoutDialogVisible: false,
      clickModalClose: false,
    });

    function changeMenuIndex(index: HEADER_MENU) {
      // headerMenuIndex.value = index;
      let name = "";
      switch (index) {
        case HEADER_MENU.HOME:
          name = "home";
          break;
        case HEADER_MENU.TEACHAPP:
          window.open("https://app.iclass30.com/appdownload");
          break;
        case HEADER_MENU.OPENAPI:
          window.open(process.env.VUE_APP_OPENAPI_PATH);
          break;
        case HEADER_MENU.MUKUN:
          window.open("https://www.class30.com/index");
          break;
      }
      router.push("/" + name);
    }
    /**
     * 登录
     */
    function login() {
      window.open("https://sso.iclass30.com"); 
      // ssoClient.login(
      //   process.env.VUE_APP_APPID
      // );
    }
    /**
     * 点击退出
     */
    function wantLogout() {
      state.logoutDialogVisible = true;
    }
    /**
     * 确认退出
     */
    function logout() {
      ssoClient.logout();
      state.logoutDialogVisible = false;
      // window.location.href = "/";
    }
    return {
      ...toRefs(state),
      headerMenuIndex,
      changeMenuIndex,
      login,
      wantLogout,
      logout,
    };
  },
});
