
import { ADMIN_TYPE, USER_TYPE } from "@/typings/user";
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Types from "@/store/action-types";
import { IGlobalState } from "@/store";
declare let ssoClient: any;

export default defineComponent({
  setup() {
    const store = useStore<IGlobalState>();
    const router = useRouter();
    /**
     * 页面一开始加载
     */
    onMounted(async () => {
      ssoClient.check(process.env.VUE_APP_APPID, false);
      const userInfo = ssoClient.getUserInfo();
      const userId = userInfo.id;
      if (userId) {
        store
          .dispatch(`user/${Types.SET_USER_INFO}`, { userId: userId })
          .then((res: any) => {
            router.push("/home");
            if (
              res.admin_type === ADMIN_TYPE.SCHOOLADMIN &&
              res.user_type === USER_TYPE.TEACHER
            ) {
              window.open(process.env.VUE_APP_SCHOOL_PATH + "/school/home");
            } else if (
              res.admin_type === ADMIN_TYPE.NOADMIN &&
              res.user_type === USER_TYPE.TEACHER
            ) {
              window.open(process.env.VUE_APP_SCHOOL_PATH + "/teacherspace/");
            }
          })
          .catch((res: any) => {
              router.push("/home");
          });
      } else {
         router.push("/home");
      }
    });
    return {};
  },
});
