
import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import HomeFooter from "@/components/HomeFooter.vue";
import { IGlobalState } from "@/store";
import HEADER_MENU from "@/typings/app";

export default defineComponent({
  components: {
    HomeFooter,
  },
  setup() {
    const store = useStore<IGlobalState>();
    const state = reactive({
      activeBtn: 1
    });
    // 头部当前选中的菜单下标
    const headerMenuIndex = computed({
      get: () => store.state.app.headerMenuIndex,
      set: (val) => {
        store.state.app.headerMenuIndex = val;
      }
    });
    /**
     * 切换普教和职教下载按钮
     */
    function changeBtn(index: number) {
      state.activeBtn = index;
    }
     /**
     * 页面一开始加载
     */
    onMounted(async () => {
      headerMenuIndex.value = HEADER_MENU.TEACHAPP;
    });
    return {
      ...toRefs(state),
      changeBtn
    };
  }
});
