
  import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
  import { useStore } from "vuex";
  import { IGlobalState } from "@/store";
  
  export default defineComponent({
    components: {
    },
    setup() {
      const store = useStore<IGlobalState>();
      const state = reactive({
        imgList:[
            require('@/assets/classroom/one.png'),
            require('@/assets/classroom/two.png'),
            require('@/assets/classroom/three.png'),
            require('@/assets/classroom/four.png'),
            require('@/assets/classroom/five.png'),
            require('@/assets/classroom/six.png'),
            require('@/assets/classroom/seven.png'),
            require('@/assets/classroom/eight.png')
        ]
      });
      /**
       * 页面一开始加载
       */
      onMounted(async () => {
        
      });
      return {
        ...toRefs(state),
      };
    },
  });
  