
import { defineComponent, onMounted } from "vue";
// import Swiper, {
//   Autoplay,
//   EffectCoverflow,
//   EffectCube,
//   Pagination,
//   Navigation,
// } from "swiper";
// Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);
// // swiper-bundle.min.css 决定了小圆点和左右翻页，如果不需要可以不引用
// import "swiper/swiper-bundle.min.css";
// // swiper.less/sass/css 决定了基础的样式
// import "swiper/swiper.scss";
export default defineComponent({
  setup() {
    // onMounted(() => {
    //   new Swiper(".swiper1", {
    //     pagination: {
    //       el: ".swiper-pagination",
    //       clickable :true
    //     },
    //     // navigation: {
    //     //   nextEl: ".swiper-button-next",
    //     //   prevEl: ".swiper-button-prev",
    //     //   hideOnClick: true,
    //     // },
    //     autoplay: {
    //       delay: 3000,
    //       stopOnLastSlide: false,
    //       disableOnInteraction: false,
    //     },
    //     on: {
    //       navigationShow: function () {
    //         console.log("按钮显示了");
    //       },
    //     },
    //   });
    // });
    return {};
  },
});
