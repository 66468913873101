
import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import HomeBanner from "@/components/HomeBanner.vue";
import HomeFooter from "@/components/HomeFooter.vue";
import { useRouter } from "vue-router";
import { IGlobalState } from "@/store";
import HEADER_MENU from "@/typings/app";

export default defineComponent({
  components: {
    HomeBanner,
    HomeFooter,
  },
  setup() {
    const store = useStore<IGlobalState>();
    const router = useRouter();
    const state = reactive({
      imgShowType: 1, // 1 默认图 2：悬浮图
    });
    // 头部当前选中的菜单下标
    const headerMenuIndex = computed({
      get: () => store.state.app.headerMenuIndex,
      set: (val) => {
        store.state.app.headerMenuIndex = val;
      },
    });
    function changeBgImg(type: number) {
      state.imgShowType = type;
    }
    /**
     * @name 学生端跳转
     */
    const goToStu = () =>{
      // debugger;
      router.push('/classroom');
    }
    /**
     * 页面一开始加载
     */
    onMounted(async () => {
      headerMenuIndex.value = HEADER_MENU.HOME;
    });
    return {
      ...toRefs(state),
      changeBgImg,
      goToStu
    };
  },
});
