
import {
  defineComponent,
  onMounted,
  onBeforeMount,
  ref,
  reactive,
  toRefs,
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import {
  getWXUserInfoAPI,
  bindQuickLoginAPI,
  userLoginAPI,
} from "@/service/api";
import { ElMessage } from "element-plus";

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      isBind: true,
      userName: "",
      userPwd: "",
      wxCode: route.query.code,
      wxOpenid: "",
      wxUnionid: "",
      redirectUri: route.query.state,
      isShowMoreUser: false,
      curUserRoleList: [],
      userType: "",
    });
    onBeforeMount(async () => {
      getWXUserInfo();
    });

    onMounted(() => {});
    /**
     * @name 登录校验
     */
    const bindLogin = async () => {
      let parmas = {
        account: state.userName,
        passWord: state.userPwd,
        logintype: 5,
        userType: state.userType,
      };
      let res = await userLoginAPI(parmas);
      if (res.code == 1) {
        bindWXLoginInfo(res.data.id);
      } else if (res.code == 2) {
        state.curUserRoleList = res.data;
        state.isShowMoreUser = true;
      } else {
        ElMessage({ message: res.msg, type: "error" });
      }
    };
    /**
     * @name 获取微信扫码结果
     */
    const getWXUserInfo = async () => {
      let params = {
        code: state.wxCode,
        type: "web",
      };
      let res = await getWXUserInfoAPI(params);
      if (res.code == 1) {
        state.wxOpenid = res.data.openid;
        state.wxUnionid = res.data.unionid;
        bindWXLoginInfo();
      } else {
        ElMessage({ message: "获取微信登录信息失败，请重试", type: "error" });
      }
    };
    /**
     * @name 获取微信绑定用户信息
     */
    const bindWXLoginInfo = async (userid?: string) => {
      let params = {
        userid: userid,
        unionid: state.wxUnionid,
        type: 1, //1：微信2：企业微信 3：钉钉 4：学科网 5：腾讯智启 6：钉钉
      };
      let res = await bindQuickLoginAPI(params);
      if (res.code == 1) {
        // window.location.replace(`${state.redirectUri}?token=${res.data.token}`);
        postMessage(res.data.token);
      } else if (res.code == 2) {
        state.curUserRoleList = res.data;
        state.isShowMoreUser = true;
      } else {
        state.isBind = false;
      }
    };
    const cancel = () => {
      state.userType = "";
      state.isShowMoreUser = false;
    };
    const selectUserRole = (item: any) => {
      if (state.isBind) {
        postMessage(item.token);
        // window.location.replace(`${state.redirectUri}?token=${item.token}`);
      } else {
        state.userType = item.user_type.toString();
      }
    };
    /**
     * @name 用户类型数字转换为中文字符串
     */
    const convertUserType = (userType: number) => {
      switch (userType) {
        case 0:
          return "注册用户";
          break;
        case 1:
          return "教师";
          break;
        case 2:
          return "学生";
          break;
        case 3:
          return "家长";
          break;
        case 4:
          return "代理商";
          break;
        case 5:
          return "运营";
          break;
        case 6:
          return "区域管理员";
          break;
      }
    };
    const postMessage = (token: string) => {
      window.parent.postMessage(
        JSON.stringify({
          type: "wxLogin",
          data: { token: token },
        }),
        "*"
      );
    };
    return {
      ...toRefs(state),
      bindLogin,
      convertUserType,
      selectUserRole,
      cancel,
    };
  },
});
