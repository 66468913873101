export interface IUserState {
    token: string;
    userId: string;
    userName: string;
    realName: string;
    avatar: string;
    userType: number | string;
    adminType: number | string;
    schoolId: string;
    schoolName: string;
    subjectId: string;
    subjectName: string;
}

// 管理员类型 0为默认什么都不是 1为总管理员 2为校级管理员 3为班级管理员
export enum ADMIN_TYPE {
    NOADMIN,
    SYSTEMADMIN,
    SCHOOLADMIN,
    CLASSADMIN
}

// 用户类型：1：教师 2：学生 3：家长 0：注册用户 4：代理商 5:运营
export enum USER_TYPE {
    REGISTER,
    TEACHER,
    STUDENT,
    PARENT,
    AGENT,
    OPERATE
}