
import { defineComponent } from "vue";
import HomeHeader from "@/components/HomeHeader.vue";

export default defineComponent({
  components: {
    HomeHeader
  },
  setup() {
   return {};
  },
});
